/**
 * @file
 * Homepage modules
 *
 */

// Banner
article.node-full.node-banner {
    padding-left: 0;
    padding-right: 0;
}

// new books
#block-views-catalogo-homepage {
    @include wrapperize;
    margin-top: 80px;
    margin-bottom: 80px;
    @include medium {
        padding-left: 40px;
        padding-right: 40px;
        .view-catalogo {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

// quotes
#block-views-homepage_quote-block {
    @include wrapperize;
    padding: 16px;
    margin-top: 80px;
    background: #000;

    > .inner {
        padding: $huge_space $big_space $big_space;
        border: 2px dashed rgba(255, 255, 255, 0.4);
        color: #fff;
        text-align: center;
    }
    header {
        height: 0;
        h1 {
            display: inline-block;
            padding: 0 1em;
            margin-bottom: 0;
            background: black;
            position: relative;
            top: -84px;
            text-transform: uppercase;
            width: 190px;
            padding-bottom: 10px;
            font-size: 200%;
            color: white;
            font-weight: 300;
            border-bottom: 1px solid white;
        }
    }
    .views-field-title {
        font-family: "TRY Vesterbro";
        font-weight: 300;
        font-size: 160%;
        margin-bottom: 1.5em;
    }
    .views-field-body {
        p {
            font-style: italic;
            font-family: "TRY Vesterbro";
            font-weight: 600;
            font-size: 240%;
            margin-bottom: 0;
        }
    }
}

// about us
#block-views-nosotros-homepage {
    @include wrapperize($big_space);
    margin-top: 80px;
    
    .views-row {
        .views-view-fields {
            @extend %clearfix;
        }
        .views-field-title-field {
            h3 {
                font-size: 300%;
                font-family: $base-font-family;
                margin-bottom: 1.1em;
            }
        }
        .views-field-field-image {
            float: left;
            width: 48%;
            margin-right: 2%;
            img {
                width: 100%;
            }
            @include small {
                width: 100%;
                float: none;
                margin-right: 0;
            }
        }
        .views-field-body {
            column-count: 2;
            column-gap: $huge_space;
            @include small {
                column-count: 1;
            }
            ul {
                padding-left: 23px;
                padding-left: 23px;
                li {
                    list-style: disc;
                    font-size: $base-font-size;
                    line-height: $base-line-height;
                    margin: 0 0 .5em;
                    @media screen and (max-width: $medium_screen_width) {
                        font-size: $base-font-size-smaller;
                    }
                }
            }
        }
        .views-field-field-image + .views-field-body {
            float: left;
            width: 50%;
            column-count: auto;
            @include small {
                column-count: auto;
                float: none;
                width: 100%;
                padding-top: $small_space;
            }
        }
    }
    .quick-tabs {
        margin: 0 auto 60px;
        text-align: center;
        @include small {
            display: block;
        }
        li {
            display: inline-block;
            @include small {
                display: block;
                margin-bottom: 10px;
            }

            &:first-child {
                a {
                    background: none;
                }
            }
            &.active {
                a {
                    text-decoration: underline;
                }
            }
            a {
                padding: 15px 12px;
                color: $theme_grey_light;
                font-size: 200%;
                font-weight: 900;
                outline: none;
                background: url("../img/vertical-dash.png") repeat-y left top;
                &:hover {
                    text-decoration: underline;
                }
                @include small {
                    background: none;
                }
            }
        }
    }
}
