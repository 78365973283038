/**
 * @file
 * Book modules
 *
 */

// list
.view-catalogo {
    @include wrapperize($big_space);
    .view-content {
        > .item-list {
            > ul {
                display: flex;
                flex-wrap: wrap;
            }
        }
    }

    .views-row {
        width: 31.666%;
        margin-right: 2.5%;
        margin-bottom: 30px;

        &:nth-child(3n) {
            margin-right: 0;
        }

        @include medium_big {
            width: 47.5%;
            margin-right: 4%;

            &:nth-child(3n) {
                margin-right: 4%;
            }
            &:nth-child(2n) {
                margin-right: 0;
            }
        }
        @include micro {
            width: 100%;

            &:nth-child(n) {
                margin-right: 0;
            }
        }
    }
}

.book-cover {
    position: relative;
    display: block;
    cursor: pointer;

    &:hover {
        .overlay {
            display: block;
        }
    }

    .field-type-image {
        border: 50px solid $theme_grey_lightest;
        @include medium {
            border: 25px solid $theme_grey_lightest;
        }
    }

    .overlay {
        display: none;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(black, .7);
        color: white;

        &.darktext {
            color: black;
        }

        .inner {
            padding: 50px;
            position: relative;
            @include medium {
                padding: 25px;
            }
        }
    }

    .field-name-field-book-visible-title h3 {
        font-family: $base-font-family;
        font-size: 240%;
        @include medium {
            font-size: 180%;
        }
    }
    .field-name-field-author {
        font-size: 180%;
        margin-top: 2em;
    }
}

// detail
.node-type-book {
    h1.page-title {
        color: $theme_red;
        font-size: 480%;
        text-align: center;
    }
}

// bookstore links
.bookstore-links {
    .bookstore-links-type {
        position: relative;
        padding-top: 12px;
        border-top: 1px solid black;

        &:first-child {
            padding-top: 0;
            border-top: none;
        }

        &[data-type="ebook"] {
            > h3::before {
                content: "T";
            }
        }

        > h3 {
            @include iconize("U", 1.7em);
            position: absolute;
            top: 12px;
            left: 0;
            font-family: $base-font-family;
            font-size: 180%;

            &:before {
                position: relative;
                top: 4px;
                margin-right: 12px;
            }
        }

        .items {
            ul {
                padding-left: 110px;
                li {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    padding: 12px 0;
                    border-top: 1px solid $theme_grey_lighter;

                    &:first-child {
                        border-top: none;
                    }

                    img {
                        max-width: 85px;
                    }

                    a {
                        @include buttonize;
                        @include iconize("E", .7em);
                        font-size: 120%;
                        margin-left: 20px;
                        padding: 8px 5px;
                        font-weight: normal;

                        &:before {
                            color: white;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }
}

.node-book.node-teaser {
    .buy-link {
        padding: 12px 0 0;
        text-align: center;
        a {
            @include iconize("Q", 2.4em);

            &:hover {
                &:before {
                    color: $theme_red;
                }
            }

            span {
                display: none;
            }
        }
    }

    .bookstore-links-popup {
        display: none;
    }
}

.bookstore-links-popup {
    > header {
        padding: 20px;
        background: black;

        .field-name-field-book-visible-title {
            h3 {
                font-family: $base-font-family;
                color: $theme_red;
                font-size: 240%;
                margin-bottom: .4em;
            }
        }

        .field-name-field-author {
            color: white;
            font-size: 180%;
        }
    }

    > .content {
        padding: 20px;
        background: white;
        display: flex;

        .column-left {
            width: 25%;
            margin-right: 10%;
        }

        .column-right {
            width: 65%;
        }
    }
}

article.node-full.node-book {
    @extend %shadowline;
    > .inner {
        @extend %clearfix;

        > aside {
            width: 31%;
            float: left;

            @include small {
                @include one-columnize();
            }

            section {
                background: $theme_grey_lightest;
                padding: 15px 20px;
                margin-bottom: 10px;

                &.bookstores {
                    padding: 0;
                    background: none;

                    > h3 {
                        background-color: black;
                        color: white;
                        padding: 15px 20px;
                    }
                    > .inner {
                        padding: 5px 12px 12px;
                    }
                }

                > h3 {
                    font-family: $base-font-family;
                    font-size: 200%;
                }
                > .inner {
                    padding-top: 20px;
                }

                &.collapsable {
                    > h3 {
                        cursor: pointer;
                        &:after {
                            float: right;
                            content: "-";
                        }
                    }
                    &.closed {
                        > h3 {
                            &:after {
                                content: "+";
                            }
                        }
                    }
                }
            }

            .field {
                margin-bottom: 15px;
                .field-label, .field-items, .field-item {
                    float: none;
                    display: inline-block;
                }

                .field-label {
                    font-size: 160%;
                }
                .field-items {
                    font-size: 160%;
                }
            }

            .field-name-field-image {
                margin-bottom: $big_space;
            }

            .field-name-field-tags {
                .field-items {
                    .field-item {
                        @include separate-by-commas;
                    }
                }
            }

            .field-type-image, .field-type-file {
                .file {
                    img, .file-size {
                        display: none;
                    }
                    a {
                        @include iconize("\004c", 1.7em);
                        color: black;
                        &:before {
                            margin-right: 10px;
                        }
                    }
                }
            }

            .ebook {
                margin-top: $big_space;
            }
            .field-name-field-press-links {
                .field-item {
                    display: block;
                }
            }

             .field-type-file {
                 .field-item {
                     display: block;
                     margin-bottom: 10px;
                 }
            }

        }

        > .content {
            width: 69%;
            float: right;
            padding-left: $huge_space;
            @include small {
                @include one-columnize();
            }

            > h2 {
                font-size: 380%;
                font-family: $base-font-family;
                margin-bottom: .5em;
                @include big_screen {
                    font-size: 320%;
                }
            }

            .field-label, > h3 {
                font-family: $base-font-family;
                font-size: 240%;
                margin: 80px 0 40px;
            }
            
            .field-name-field-author {
                a {
                    color: black;
                    text-decoration: underline;
                    font-size: 280%;
                }
            }
        }
    }

    .view-catalogo {
        padding-left: 0;
        padding-right: 0;
    }
}

.view-related-books {
    .item-list {
        > ul {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-gap: 2rem;
            gap: 2rem;

            @include medium_big {
                display: block;
            }
        }
    }

    .views-row {
        @include medium_big {
            margin-bottom: 30px;
        }
    }
}

.field-name-field-press-links {
    .field-item {
        margin-bottom: 20px;
    }
    a {
        color: black;
        &:before {
            content: "- ";
        }
    }
}

.share-links {
    text-align: center;
    margin-top: $small_space;
    li {
        display: inline-block;
        padding: 11px 12px;
        color: #9B9B9B;
        font-size: 200%;
        font-weight: 900;
        outline: none;
        background: url(../img/vertical-dash.png) repeat-y left top;
        &:first-child {
            background: none;
        }

        a {
            color: $theme_grey_lighter;
        }
    }
}
