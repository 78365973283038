/**
 * @file
 * Search modules
 *
 */

// search block
#block-search-form {
    position: absolute;
    top: 44px;
    right: 40px;
    width: 50px;
    background: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    transition: border-color .3s ease;
    body.header-fixed & {
        top: 16px;
    }
    @include micro {
        top: 16px;
    }
    &.opened {
        width: auto;
        border-bottom: 1px solid rgba(0, 0, 0, 1);

        input[type="text"] {
            opacity: 1;
        }
    }
    .container-inline div {
        display: inline-block;
        margin-bottom: 0;
        padding-bottom: 10px;
    }
    input[type="text"] {
        opacity: 0;
        transition: opacity .3s ease;
        color: $theme_grey_light;
        border: 0;
        font-size: 140%;
        padding: 7px;
        outline: 0;
        width: 200px;
    }
    input[type="submit"] {
        display: none;
    }
    [data-action="real-search-submit"] {
        @include iconize('\0042', 220%);
        font-weight: normal;
        background: none;
        outline: none;
        padding: 0;
        position: absolute;
        top: -8px;
        right: 0;
        body.header-fixed & {
            top: 6px;
            &:before {
                font-size: 160%;
            }
        }
        @include micro {
            top: 6px;
            &:before {
                font-size: 160%;
            }
        }
    }
}

// search page
body.page-search {
    #content-inner {
        @include wrapperize($huge_space);
    }

    .search-advanced {
        display: none;
    }

    .search-form {
        padding-top: 54px;

        .container-inline div {
            display: inline-block;
        }
        + h2 {
            font-size: 30px;
            margin: 1em 0;
            + ul li {
                font-size: 180%;
                margin-bottom: 10px;
                list-style: disc;
                margin-left: $small_space;
            }
        }
    }
    .search-results {
        .title {
            font-size: 2em;
            a {
                color: $theme_grey_light;
            }
        }
        .search-snippet-info {
            padding-left: 0;
        }
    }
}
