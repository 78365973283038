// import external fonts

// Vesterbro
@include font-face('TRY Vesterbro', '../fonts/TRYVesterbro-Light', 300);
@include font-face('TRY Vesterbro', '../fonts/TRYVesterbro-Medium', 500);
@include font-face('TRY Vesterbro', '../fonts/TRYVesterbro-Extrabold', 800);

// Montserrat
@import url('//fonts.googleapis.com/css?family=Montserrat:400,400i,500,500i,600,600i&subset=latin-ext');

// Icons font
@include font-face('KoanIcons', '../fonts/KOAN_web_icons_font', 'normal');
