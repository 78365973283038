/**
 * @file
 * Page modules
 *
 */

#page {
    position: relative;
    z-index: 1;
    overflow: hidden;
}

body.header-fixed {
    padding-top: $header-fixed-height;
}

#header {
    position: relative;
    z-index: 20;
    height: 120px;
    overflow: hidden;
    body.header-fixed & {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        height: $header-fixed-height;
        background-color: white;
    }
    body.admin-menu.adminimal-menu.header-fixed & {
        top: 28px;
    }
    @include micro {
        height: $header-fixed-height;
    }
    > .inner {
        @include wrapperize;
        position: relative;
        height: 100%;
        body.header-fixed & {
            @extend %clearfix;
        }
    }
    .logo {
        margin: 0 auto;
        display: block;
        width: 207px;
        body.header-fixed & {
            width: 130px;
        }
        @include micro {
            width: 130px;
        }
    }
}

// social links block
.social-networks-links {
    position: absolute;
    top: 45px;
    right: 0;
    body.header-fixed & {
        display: none;
    }
    @media screen and (max-width: $page_inner_maxwidth) {
        right: $page_horizontal_padding;
    }
}

// navigation
.region-navigation {
    position: relative;
}

// main menu
#block-system-main-menu {
    display: none;
}

// responsive menu
.mobile-menu {
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    transition: all .5s ease;
    &.opened {
        width: 320px;

        &.expanded {
            width: 640px;
            @include small {
                width: 320px;
            }
        }
        nav {
            display: block;
        }
    }
    [data-action="open-mobile-menu"] {
        @include iconize("\0043", 140%);
        position: fixed;
        top: 45px;
        width: 35px;
        height: 35px;
        left: $big_space;
        outline: none;
        z-index: 2;
        background: none;
        border: none;
        padding: 0;
        body.header-fixed & {
            top: 20px;
            &:before {
                font-size: 100%;
            }
        }
        @include micro {
            top: 20px;
            &:before {
                font-size: 100%;
            }
        }
    }
    &.opened [data-action="open-mobile-menu"] {
        &:before {
            content: "\0044";
            color: white;
        }
    }
    nav {
        display: none;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 123px $big_space 40px;
        z-index: 1;
        background: black;
        > ul.menu {
            width: 240px;
            > li {
                position: relative;
                list-style: none;
                transition: all .5s ease;
                &:hover, &.active-trail {
                    padding-left: 10px;
                    ul.menu {
                        left: 240px;
                        opacity: 1;
                        @include small {
                            left: 0;
                            position: relative;
                        }
                    }
                    > a {
                        color: white;
                    }
                    &.expanded {
                        > a {
                            position: relative;
                            @include iconize("\0045", .4em);
                            border-bottom: 1px solid white;
                            &:before {
                                color: white;
                                position: absolute;
                                top: 50%;
                                right: 0;
                            }
                        }
                    }
                }
                &.active-trail {
                    > a {
                        font-weight: 900;
                    }
                }
                a {
                    display: block;
                    font-size: 240%;
                    font-weight: 500;
                    padding: 8px 0;
                    color: $theme_grey_lighter;
                    transition: all .5s ease;
                }
                ul.menu {
                    position: absolute;
                    top: 8px;
                    left: 0;
                    width: 240px;
                    opacity: 0;
                    padding-left: 20px;
                    transition: all .5s ease;
                    li.menu-views {
                        .views-row {
                            transition: all .5s ease;
                            &:hover, &.active-trail {
                                padding-left: 10px;
                            }
                            a {
                                font-size: 160%;
                                font-weight: normal;
                                color: white;
                                padding: 10px 0;
                            }
                        }
                    }

                    .view-footer {
                        p {
                            font-size: 1em;

                            a {
                                &:hover {
                                    padding-left: 10px;
                                }

                                transition: all .5s ease;
                                font-size: 160%;
                                font-weight: normal;
                                color: white;
                                padding: 10px 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

// secondary menu
#block-views--exp-catalogo-catalogo {
    display: none;
}
#block-menu-menu-secondary {
    @include wrapperize($big_space);
    position: relative;
    z-index: 2;
    margin-bottom: 50px;
    nav {
        > ul.menu {
            @extend %clearfix;
            > li {
                float: left;
                margin-right: 40px;

                &.menu-exposed {
                    position: relative;
                    float: right;
                    margin-right: 0;
                    &:hover {
                        .views-exposed-widget > label {
                            &:before {
                                content:"\0049";
                            }
                            cursor: pointer;
                        }
                        .views-widget {
                            display: block;
                        }
                    }
                }

                > a, .views-exposed-widget > h3 {
                    font-family: $base-font-family;
                    line-height: 1;
                    display: block;
                    padding: 18px 0;
                    font-size: 240%;
                    font-weight: 500;
                    color: black;
                    @include micro {
                        font-size: 180%;
                    }
                    &:hover {
                        color: $theme_grey_light;
                    }
                }

                > a.active {
                    color: $theme_red;
                    font-weight: 900;
                }

                .views-exposed-form {
                    .views-exposed-widgets {
                        margin-bottom: 0;
                        .views-exposed-widget {
                            margin-bottom: 0;
                            > label {
                                @include iconize("\004a", .5em);
                                padding: 18px 18px 18px 0;
                                margin-bottom: 0;
                                display: block!important;
                                font-family: $base-font-family;
                                line-height: 1;
                                font-size: 240%;
                                font-weight: 500;
                                color: black;
                                text-transform: none;
                                &:before {
                                    position: absolute;
                                    top: 24px;
                                    right: 0;
                                }
                                @include micro {
                                    font-size: 180%;
                                }
                            }
                        }
                    }
                }

                .views-widget {
                    display: none;
                    position: absolute;
                    top: 46px;
                    right: 0;
                    width: 200px;
                    background: white;
                    border: 1px dotted black;
                    padding: 5px 10px;

                    .form-item {
                        font-size: 100%;
                        a {
                            display: block;
                            font-size: 160%;
                            color: black;
                            padding: 5px 0;
                            &:hover {
                                font-weight: 900;
                            }
                            &.active {
                                color: $theme_red;
                                font-weight: 900;
                            }
                        }
                    }
                }
            }
        }
    }
}

#content {
    padding: 0 0 $huge_space;
    body.not-front & {
        padding-top: $big_space;
    }
    body.one-sidebar & {
        @extend %clearfix;
    }
}

#content-inner {
    body.one-sidebar & {
        width: 75%;
        @media screen and (max-width: $medium_screen_width) {
            width: auto;
        }
    }
    body.sidebar-first & {
        float: right;
        padding-left: 14px;
        @media screen and (max-width: $medium_screen_width) {
            @include one-columnize;
        }
    }
    body.sidebar-second & {
        float: left;
        padding-right: 14px;
        border-right: 1px solid $theme_grey_light;
        @media screen and (max-width: $big_screen_width) {
            @include one-columnize;
            border-right: none;
        }
    }

    // page title
    > h1.page-title {
        @include wrapperize;
        @extend %page-title;
        padding-bottom: 0;
        &:after {
            content: " ";
            display: block;
            max-width: ($page_inner_maxwidth - 2 * $big_space);
            border-bottom: 1px solid black;
            margin: 50px auto 0;
            @media screen and (max-width: ($page_inner_maxwidth - 2 * $big_space)) {
                max-width: 100%;
            }
        }
    }
}

// highlighted
.region-highlighted {
    > div {
        @include wrapperize;
    }
}

// sidebars
#sidebar-first {
    float: left;
    width: 25%;
    padding-right: 15px;

    @media screen and (max-width: $medium_screen_width) {
        @include one-columnize;
    }
}

#sidebar-second {
    float: right;
    width: 25%;
    padding-left: 15px;

    @media screen and (max-width: $big_screen_width) {
        @include one-columnize;
    }
}

section.block {
    header h1 {
        @extend %page-title;
        color: black;
        padding-bottom: 1.4em;
    }
}

// detail
article.node-full {
    @include wrapperize($big_space);

    body.not-front & {
        > .inner {
            padding-top: 60px;
        }
    }

    h1.page-title {
        @extend %page-title;
    }

    .field-type-text-with-summary, .field-type-text-long {
        max-width: $body_width;
        margin: 0 auto 30px;
        p {
            a {
                text-decoration: underline;
            }
            a.btn {
                @include buttonize;
                font-size: 100%;
                display: block;
            }
        }
        h2 {
            font-family: $base-font-family;
            font-size: 220%;
            line-height: 1.1;
            padding-top: .7em;
            &:first-child {
                padding-top: 0;
            }
            @media screen and (max-width: $mediumbig_screen_width) {
                font-size: 160%;
                line-height: 1.2;
            }
        }
        h3 {
            font-family: $base-font-family;
            font-size: 180%;
            line-height: 1.2;
        }
        li {
            font-size: $base-font-size;
            line-height: $base-line-height;
            margin: 0 0 .5em;
            @media screen and (max-width: $medium_screen_width) {
                font-size: $base-font-size-smaller;
            }
        }
        ul {
            padding-left: 23px;
            padding-left: 23px;
            li {
                list-style: disc;
            }
        }
        table {
            margin-bottom: 20px;
            th, td {
                font-size: 120%;
            }
        }
        iframe {
            margin: 30px 0;
        }
        blockquote {
            margin: 50px 0;
            p {
                margin-bottom: 0;
                font-size: 240%;
                line-height: 1.25;
            }
        }
    }

    // Page detail
    &.node-page {
        p {
            font-size: 180%;
        }
    }
}

// contact form
form.contact-form {
    max-width: 600px;
    margin: 0 auto;
    padding-top: 54px;
}

//--- Footer ---
#footer {
    position: relative;
    padding: 40px 0;
    background-color: #fff;
    box-shadow: inset 0 3px 3px 0 rgba(black, .14);

    > .inner {
        @include wrapperize;
    }
    .logo img {
        width: 207px;
    }

    @include small {
        text-align: center;
    }
}

.region-footer {
    @extend %clearfix;
}

#block-menu-menu-footer-menu {
    float: left;
    margin-left: 200px;

    a {
        font-size: 240%;
        color: $theme_grey_light;
    }

    @include small {
        float: none;
        margin-left: 0;
    }
}

// footer bootom
.region-footer-bottom {
    padding-top: 40px;
}

// legal menu
#block-menu-menu-legal {
    margin-left: 200px;
    @include small {
        margin-left: 0;
    }
    nav {
        ul.menu {
            margin: 0;
            padding: 0;
            li {
                display: inline;
                list-style: none;
                margin-right: 20px;
                @include small {
                    display: block;
                    margin-right: 0;
                    margin-bottom: 10px;
                }
                a {
                    color: $theme_grey_light;
                    font-size: 160%;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                &.active-trail a, a.active {
                    text-decoration: underline;
                }
            }
        }
    }
}

#block-koan-newsletter_call_action {
    float: right;
    margin-bottom: $small_space;
    @include small {
        float: none;
        margin-top: $small_space;
    }

    p {
        color: $theme_grey_light;
        margin-bottom: 0;
        a[data-action] {
            margin-left: 10px;
            font-size: 100%;
        }
    }
}

// cookies sliding popup
#sliding-popup {
    width: 100%;
    > div {
        @include wrapperize($big_space);
    }
    .popup-content {
        float: right;
        position: relative;
        bottom: $big_space;
        max-width: none;
        display: block;
        width: 50%;
        padding: 10px;
        margin: 0;
        background: white;
        border: 1px dotted black;

        button {
            background-color: transparent;
            color: black;
            font-size: 120%;
            font-weight: normal;
            border: 0;
            padding: 0;
        }

        #popup-text {
            float: none;
            max-width: none;
            h2 {
                font-size: 160%;
                font-family: $base-font-family;
                margin-bottom: .6em;
            }
            p {
                font-size: 160%;
                color: black;
                margin-bottom: 10px;
            }
            button {
                text-decoration: underline;
            }
        }

        #popup-buttons {
            float: none;
            max-width: none;
            position: absolute;
            top: 8px;
            right: 5px;

            button.agree-button {
                font-size: 1px;
                color: white;
                @include iconize("\004f", 12px);
            }
        }
    }
}
