/**
 * @file
 * Author modules
 *
 */

// list
.view-authors {
    @include wrapperize($big_space);
    .view-content {
        > .item-list {
            > ul {
                @include simple-column-container;
            }
        }
    }
    .views-row {
        @include simple-column(3, 2.6%);
        @include small {
            @include simple-column(2, 2.6%);
            margin-bottom: 2.6%;
        }
        @include micro {
            @include simple-column(1, 0);
        }
        &:hover {
            .views-field-field-image {
                border-color: #000;
            }
        }
    }

    .views-field-field-image {
        border: 32px solid $theme_grey_lightest;
        transition: border-color .3s ease;
    }
    .views-field-title-field {
        text-align: center;
        display: block;
        font-size: 240%;
        height: 3em;
        padding: 1em 0 1.5em;
        overflow: hidden;
        a {
            color: black;
        }
    }
}

// detail
article.node-full.node-author {
    @extend %shadowline;
    > .inner {
        @extend %clearfix;

        > aside {
            width: 31%;
            float: left;
            @include small {
                @include one-columnize();
            }

            section {
                background: $theme_grey_lightest;
                padding: 15px 20px;
                margin-bottom: 10px;

                h3 {
                    font-family: $base-font-family;
                    font-size: 200%;
                }
            }

            .field {
                margin-bottom: 5px;
                .field-label, .field-items, .field-item {
                    float: none;
                    display: inline;
                }

                .field-label {
                    font-weight: normal;
                    font-size: 160%;
                }
                .field-items {
                    word-break: break-word;
                    font-size: 160%;
                }
            }

            .field.field-name-field-image {
                margin-bottom: $big_space;
            }

            .field-name-field-link {
                font-weight: 900;
                a {
                    color: black;
                }
            }
        }
        > .content {
            width: 69%;
            float: right;
            padding-left: $huge_space;
            @include small {
                @include one-columnize();
            }

            .field-label {
                font-size: 240%;
                margin: 80px 0 40px;
            }

            h2 {
                font-size: 380%;
                font-family: $base-font-family;
                margin-bottom: 1.2em;
                @include big_screen {
                    font-size: 320%;
                }
            }
        }
    }

    // books list
    .view-catalogo {
        padding-left: 0;
        padding-right: 0;
    }
    .book-cover {
        .field-type-image {
            border: none;
        }
        .overlay {
            .inner {
                padding: 25px;
            }
        }
    }
}