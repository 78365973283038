/**
 * @file
 * Distribucion modules
 *
 */

.view-distribucion {
    @include wrapperize($big_space);
    padding-top: 54px;
    .view-grouping {
        clear: both;
        @extend %clearfix;
        margin-bottom: $big_space;
    }
    .view-grouping-header {
        width: 34%;
        float: left;
        font-size: 300%;
        font-weight: bold;
        @include medium {
            @include one-columnize;
        }
    }
    .view-grouping-content {
        width: 66%;
        float: left;
        @include medium {
            @include one-columnize;
        }
        > .item-list {
            > h3 {
                padding-top: .2em;
                margin-bottom: 1.2em;
                font-size: 240%;
                font-family: $theme_font;
            }
        }
    }
    .views-row {
        margin-top: 30px;
        margin-bottom: 40px;
        &.views-row-first {
            margin-top: 0;
        }
        .views-field-field-subregion {
            font-size: $base-font-size;
            font-family: $theme_font;
            font-weight: bold;
        }
        .views-field-title, .views-field-field-address p {
            font-size: $base-font-size;
            margin-bottom: 0;
        }
        .views-field-field-address a {
            color: #000;
        }
    }
}