// @file
// Custom sass mixins

// a clearfix extension
%clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

%narrowbody {
    max-width: $body_width;
}

// page title
%page-title {
    font-size: 480%;
    line-height: 1.1;
    padding-bottom: 1em;
    color: $theme_red;
    text-align: center;

    @media screen and (max-width: $big_screen_width) {
        font-size: 320%;
    }
}

// fullwidth shadow on top
%shadowline {
    &:before {
        content: " ";
        position: absolute;
        width: 100%;
        height: 6px;
        left: 0;
        box-shadow: inset 0 3px 3px 0 rgba(black, .14);
    }
}

// go back link
%go-back-link {
    background: none;
    color: $theme_grey_light;
    border: none;
    text-transform: none;
    margin-bottom: 20px;
    &:before {
        content: "<";
        margin-right: 5px;
    }
}

// pager item
%pager__item {
    display: inline;
    font-size: 140%;
    padding: 0 8px;
    text-align: center;
    list-style-type: none;
    background-image: none;
    a {
        color: black;
    }
}

// wrapperize
@mixin wrapperize($extra-padding: 0) {
    margin-left: auto;
    margin-right: auto;
    max-width: $page_inner_maxwidth;
    padding-left: $extra-padding;
    padding-right: $extra-padding;
}

// Makes an element visually hidden, but accessible.
// @see http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
@mixin element-invisible {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}

// Turns off the element-invisible effect.
@mixin element-invisible-off {
    position: static !important;
    clip: auto;
    height: auto;
    width: auto;
    overflow: auto;
}

// Makes an element visually hidden by default, but visible when focused.
@mixin element-focusable {
    @include element-invisible;

    &:active,
    &:focus {
        @include element-invisible-off;
    }
}

/* The Simple Grid System */
@mixin simple-column-container {
    @extend %clearfix;
}

@mixin simple-column($column-count, $gutter-width) {
    float: left;
    width: ((100% - (($column-count - 1) * $gutter-width)) / $column-count);
    &:nth-child(n) {
        margin-right: $gutter-width;
    }
    &:nth-child(#{$column-count}n) {
        margin-right: 0;
    }
}

// hide the text
@mixin hide-text() {
    text-indent: -500em;
}

// one column-ize
@mixin one-columnize() {
    float: none;
    position: static;
    width: 100%;
    height: auto;
    padding-left: 0;
    padding-right: 0;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    clear: both;
    margin: 0 auto 20px;
}

// create inline list and separate by commas
@mixin separate-by-commas() {
    display: inline-block;
    &:after {
        content: ",";
        padding: 0 .2em 0 0;
    }
    &:last-child:after {
        content: "";
        padding: 0;
    }
}

// buttons
@mixin buttonize {
    display: inline-block;
    font-size: $base-font-size;
    color: white;
    text-align: center;
    font-weight: 900;
    padding: 10px 15px 11px;
    background: #000;
    border: none;
    border-radius: 0;
    &:hover, &:active {
        background-color: $theme_grey_dark;
    }
    &[disabled] {
        &:hover, &:active {
            color: inherit;
            border-color: inherit;
            background-color: inherit;
            opacity: .6;
        }
    }
}

// icons
@mixin iconize($letter, $font_size: 100%) {
    &::before {
        display: inline-block;
        content: $letter;
        color: black;
        font-family: 'KoanIcons', Helvetica, Arial, sans-serif;
        font-weight: normal;
        font-size: $font_size;
        line-height: 1;
        vertical-align: middle;
    }
}
@mixin iconize_sprite($left: 0, $top: 0, $block_width: 1, $block_height: 1) {
    display: block;
    width: (25px * $block_width);
    height: (25px * $block_height);
    background: url("../img/sprite.png") no-repeat $left $top;
}

// mobile header
@mixin mobileheader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 5px;
    background-color: white;
    border-bottom: 35px solid white;
}

@mixin font-face($font-family, $font-file, $font-weight, $font-style: 'normal') {
    @font-face {
        font-family: $font-family;
        src: url($font-file+'.eot');
        src: url($font-file+'.eot?#iefix') format('embedded-opentype'),
        url($font-file+'.woff') format('woff'),
        url($font-file+'.ttf') format('truetype'),
        url($font-file+'.svg#aller') format('svg');
        font-weight: $font-weight;
        font-style: $font-style;
    }
}


// responsive
@mixin huge_screen {
    @media (max-width: #{$huge_screen_width}) {
        @content;
    }
}
@mixin bigger_screen {
    @media (max-width: #{$bigger_screen_width}) {
        @content;
    }
}
@mixin big_screen {
    @media (max-width: #{$big_screen_width}) {
        @content;
    }
}
@mixin medium_big {
    @media (max-width: #{$mediumbig_screen_width}) {
        @content;
    }
}
@mixin medium {
    @media (max-width: #{$medium_screen_width}) {
        @content;
    }
}
@mixin small {
    @media (max-width: #{$small_screen_width}) {
        @content;
    }
}
@mixin micro {
    @media (max-width: #{$micro_screen_width}) {
        @content;
    }
}
@mixin nano {
    @media (max-width: #{$nano_screen_width}) {
        @content;
    }
}