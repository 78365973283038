/**
* External Plugins CSS
*/
/* Slick */
@import "../node_modules/slick-carousel/slick/slick.scss";

.slick-slider {
    * {
        outline: none;
    }
}

button.slick-arrow {
    @include iconize("\0045");
    position: absolute;
    top: 50%;
    z-index: 2;
    padding: 0;
    width: 50px;
    height: 100px;
    margin-top: -50px;
    background: none;
    border: none;
    &:hover {
        background: none;
    }
    span {
        display: none;
    }
    &.slick-disabled {
        cursor: default;
        opacity: .2;
    }
    &.slick-prev {
        left: 20px;
        &:before {
            content: "\0046";
        }
    }
    &.slick-next {
        right: 20px;
    }
}

.slick-dots {
    position: relative;
    top: -35px;
    text-align: center;
    li {
        display: inline-block;
        margin-left: 5px;
        button {
            @include hide-text;
            padding: 0;
            width: 16px;
            height: 16px;
            border: 2px solid white;
            border-radius: 50%;
            background: white;
        }
        &.slick-active {
            button {
                background: $theme_red;
            }
        }
    }
}

/* overlay */
@import "../node_modules/jquery-colorbox/example1/colorbox.css";
#colorbox {
    &.bookstore-links-popup-overlay {
        #cboxContent {
            padding: 0;
        }

        #cboxClose {
            color: black;
            top: 10px;
            &:before {
                color: white;
            }
        }
    }
}

#cboxOverlay {
    background: black;
}

#cboxTopLeft, #cboxTopCenter, #cboxTopRight, #cboxMiddleLeft, #cboxMiddleRight, #cboxBottomLeft, #cboxBottomCenter, #cboxBottomRight {
    display: none;
}

#cboxLoadedContent {
    margin: 0;
}

#cboxContent {
    padding: 20px;
}

#cboxClose {
    @include iconize("\0044");
    text-indent: 0;
    overflow: hidden;
    width: 50px;
    height: 50px;
    bottom: auto;
    top: 0;
    background: none;
}
